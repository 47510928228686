class CreateOrganisation extends React.Component {
  constructor(props) {
    super(props)
    this.props = props
    this.content = null

    this.state = { accountTypes: null, accountTypesRetrieved: false, updateComponentSwitch: false }
    this.onCreation = this.onCreation.bind(this)
    this.onChangePlan = this.onChangePlan.bind(this)
    this.onConfirmPlan = this.onConfirmPlan.bind(this)
    this.updateComponentSwitch = this.updateComponentSwitch.bind(this)
  }

  onChangePlan(chosenPlan) {
    /*console.log( "onChangePlan :" + chosenPlan);*/
    /*this.setState({chosenPlan:chosenPlan.id});*/
    this.props.onChosenPlan(chosenPlan)
  }

  onCreation() {
    this.props.onCreation()
  }

  onConfirmPlan(planId) {
    /*console.log("Createorganisation onConfirmPlan");*/
    this.props.onConfirmPlan(planId)
  }

  componentDidMount() {
    firebase.database().ref("accountTypes").orderByChild("frontPageOrder").startAt(0).once("value").then((snapshot) => {
      let accountTypesTemp = []
      if (snapshot && snapshot.val()) {
        snapshot.forEach((child) => {
          /*let accountTypeTemp = {[child.key]: child.val() };*/
          let accountTypeTemp = child.val()
          /*console.log("child : " + " / " + child.val() + " / " + child.exportVal() );*/
          // console.log("accountTypeTemp : " + JSON.stringify(accountTypeTemp ));
          accountTypesTemp.push(accountTypeTemp)
        })
        this.setState({ accountTypesRetrieved: true, accountTypes: accountTypesTemp })
      }
    }, function(error) {
      // An error happened.
      DkScanHowHelpers.logError(error)
      console.log("Error while reading account types: " + error)
    })
  }

  updateComponentSwitch() {
    this.setState({ updateComponentSwitch: !this.state.updateComponentSwitch })
  }

  render() {
    /*console.log( "CreateOrganisation " );*/
    let step = 1 //Choose plan
    if (this.props.chosenPlan) {
      step = 2 //Enter credentials
      if (this.props.authUser) {
        step = 3 //verify Email
        if (this.props.authUser.emailVerified) {
          step = 4 //Institution details
          if (this.props.accountType) {
            step = 5 //ConfirmPlan
          }
        }
      }
    }

    switch (step) {
      case 1:
        this.content = <ChoosePlan accountTypes={this.state.accountTypes} onChangePlan={this.onChangePlan} />
        break
      case 2:
        this.content = <CredentialsCard chosenPlan={this.props.chosenPlan} onCreation={this.onCreation}
                                        authUser={this.props.authUser} showModalForm={this.props.showModalForm} />
        break
      case 3:
        this.content = <VerifyEmail authUser={this.props.authUser} updateComponentSwitch={this.updateComponentSwitch} />
        break
      case 4:
        this.content =
          <AccountForm chosenPlan={this.props.chosenPlan} onCreation={this.onCreation} authUser={this.props.authUser}
                       account={this.props.account} showModalForm={this.props.showModalForm} />
        break
      case 5:
        this.content = this.props.authUser ?
          <ConfirmPlan chosenPlan={this.props.chosenPlan} onConfirmPlan={this.onConfirmPlan}
                       authUser={this.props.authUser} account={this.props.account}
                       accountType={this.props.accountType} /> : null
    }

    return <div>
      <br />
      <div className="createProgressBar">
        <ul className="bigger highlight-active">
          <li className={step == 1 ? "active" : ""}></li>
          <li className={step == 2 || step == 3 ? "active" : ""}></li>
          <li className={step == 4 ? "active" : ""}></li>
          <li className={step == 5 ? "active" : ""}></li>
        </ul>
      </div>
      {this.content}
    </div>
  }
}

CreateOrganisation.defaultProps = {}
CreateOrganisation.propTypes = {
  authUser: React.PropTypes.object,
  chosenPlan: React.PropTypes.string,
  confirmedPlan: React.PropTypes.string,
  onChosenPlan: React.PropTypes.func.isRequired,
  onConfirmPlan: React.PropTypes.func.isRequired,
  accountType: React.PropTypes.object,
  account: React.PropTypes.object,
}

class VerifyEmail extends React.Component {
  constructor(props) {
    super(props)
    this.props = props
    this.resendVerificationMail = this.resendVerificationMail.bind(this)
  }

  componentDidUpdate() {
    componentHandler.upgradeDom()
  }

  componentDidMount() {
    let interval = null
    interval = setInterval(() => {
      if (firebase.auth().currentUser != null) {
        firebase.auth().currentUser.reload().then(
          () => {
            if (interval && this.props.authUser.emailVerified) {
              clearInterval(interval)
              interval = null

              firebase.auth().currentUser.getIdToken(true).then(() => {
                //firebase.auth().currentUser.getIdToken(true).then( ()=>{
                this.props.updateComponentSwitch()
              }, error => {
                //Re-login
                console.log("Error on getIdToken")
              })
            }
          }, error => {
            if (interval) {
              clearInterval(interval)
              interval = null
            }
          },
        )
      } else {
        if (interval) {
          clearInterval(interval)
          interval = null
        }
      }
    }, 1000)
  }

  resendVerificationMail() {
    if (DKScanhowSendEmailVerification) {
      firebase.auth().currentUser.sendEmailVerification().then(
        () => {
          console.log("Email verification email sent")
        }, error => {
          console.log("sendEmailVerification failed" + error.message + " (" + error.code + ")")
        })
    }
  }

  render() {
    return (
      <div className="center"><br /><br />
        <div
          dangerouslySetInnerHTML={{ __html: DkScanHowLocalisation.t("web_createorganisation_label_averificationemailhavebeensend") }} />
        <br /><br />
        <ScanHowButton text={DkScanHowLocalisation.t("web_createorganisation_btn_resendemail")}
                       onClick={this.resendVerificationMail} />
      </div>
    )
  }
}

VerifyEmail.defaultProps = {}
VerifyEmail.propTypes = {
  authUser: React.PropTypes.object.isRequired,
}

class CredentialsCard extends React.Component {
  constructor(props) {
    super(props)
    this.props = props
    this.state = { pass1: "", pass2: "", email: "", adminName: "", errMsg: "" }
    this.fieldUpdated = this.fieldUpdated.bind(this)
    this.handleContinueClick = this.handleContinueClick.bind(this)
    this.areCardsValidated = this.areCardsValidated.bind(this)
    this.updateFormState = this.updateFormState.bind(this)
    this.createUserSucces = this.createUserSucces.bind(this)
    this.createUserFailed = this.createUserFailed.bind(this)
    this.userUpdateFailed = this.userUpdateFailed.bind(this)
    this.userUpdated = this.userUpdated.bind(this)
    this.keyDownHandler = this.keyDownHandler.bind(this)
  }

  areCardsValidated() {

    return this.state.adminName != "" && this.state.email != "" && this.state.pass1 != "" && this.state.pass2 != "" && (this.state.pass1 == this.state.pass2)
  }


  validateForm() {
    if (this.state.email != "" && this.state.pass1 != "" && this.state.pass2 != "" && this.state.pass1 == this.state.pass2 && this.state.accountName != "") {
    }
  }

  updateFormState(key, value) {
    this.setState({ [key]: value }, () => this.validateForm())
  }

  fieldUpdated(e) {
    const name = e.target.name
    const value = e.target.value
    this.updateFormState(name, value)
  }

  componentDidUpdate(prevProps, prevState) {
    componentHandler.upgradeDom()
  }

  componentDidMount() {
    componentHandler.upgradeDom()
  }

  handleContinueClick() {
    if (this.areCardsValidated()) {
      this.setState({ errorMsg: null })
      if (!this.props.authUser) {
        DkScanHowHelpers.displayModal(true)
        firebase.auth().createUserWithEmailAndPassword(this.state.email, this.state.pass1).then(this.createUserSucces, this.createUserFailed)
      }
    } else {
      if (this.state.adminName == "" || this.state.email == "" || this.state.pass1 == "" || this.state.pass2 == "" || !this.state.eulaAccepted) {
        this.setState({ errorMsg: DkScanHowLocalisation.t("web_createorganisation_errormsg_requiredfieldmissing") })
      } else if (this.state.pass1 != this.state.pass2) {
        this.setState({ errorMsg: DkScanHowLocalisation.t("web_createorganisation_errormsg_identicalpasswordsrequired") })
      }
    }
  }

  keyDownHandler(e) {
    //Pressed enter
    if (e.keyCode === 13) {
      this.handleContinueClick(e)
    }
  }

  createUserSucces(user) {
    this.updateAccount(user)
  }

  updateAccount(user) {
    console.log({
      name: this.state.adminName,
      email: user.email,
      role: "admin",
      uid: user.uid,
    })
    firebase.database().ref("users/" + user.uid).update({
      name: this.state.adminName,
      email: user.email,
      role: "admin",
      uid: user.uid,
      createdTime: firebase.database.ServerValue.TIMESTAMP,
    }).then(
      this.userUpdated(user),
      this.userUpdateFailed,
    )
  }

  userUpdated(user) {
    DkScanHowHelpers.displayModal(false)
    console.log("user updated")

    if (DKScanhowSendEmailVerification) {
      user.sendEmailVerification().then(
        () => {
          console.log("Email verification sent")
        }, error => {
          console.log("sendEmailVerification failed " + error.message + " (" + error.code + ")")
        })
    }
  }

  userUpdateFailed(error) {
    console.log("userUpdateFailed")
    firebase.auth().currentUser.delete().then(function(value) {
      console.log("auth user removed")
      DkScanHowHelpers.displayModal(false)
    }, function(error) {
      // An error happened.
      DkScanHowHelpers.displayModal(false)
    }).catch(function(error) {
      DkScanHowHelpers.logError(error)
      DkScanHowHelpers.displayModal(false)
    })

    this.setState({ errorMsg: DkScanHowLocalisation.t("web_general_errormsg_anerroroccurred") })
    DkScanHowHelpers.logError(error)
  }

  createUserFailed(error) {
    let errMsg = ""
    switch (error.code) {
      case "auth/weak-password":
        switch (error.message) {
          case "Password should be at least 6 characters" :
            errMsg = DkScanHowLocalisation.t("web_createorganisation_errormsg_passwordshouldbeatleast6chars")
            break
          default:
            errMsg = DkScanHowLocalisation.t("web_general_errormsg_unsafepassword")
        }
        break
      case "auth/email-already-in-use":
        errMsg = DkScanHowLocalisation.t("web_general_errormsg_emailalreadyinsue")
        break
      case "auth/invalid-email":
        errMsg = DkScanHowLocalisation.t("web_general_errormsg_invalidemail")
        break
      default:
        errMsg = DkScanHowLocalisation.t("web_general_errormsg_anerroroccurred")
        console.log(JSON.stringify(error, null, "  "))
    }
    this.setState({ errorMsg: errMsg })
    DkScanHowHelpers.displayModal(false)
    DkScanHowHelpers.logError(error)
  }

  render() {
    return (
      <div style={{ width: 330 + "px", maxWidth: 330 + "px", margin: "auto" }}>
        <div className="mdl-card mdl-shadow--2dp ">
          <div className="mdl-card__supporting-text createOrgAdminForm">
            {DkScanHowLocalisation.t("web_createorganisation_allfieldsarerequired")}
            <br />
            <ScanHowInputTextField fieldName="adminName"
                                   placeholderText={DkScanHowLocalisation.t("web_createorganisation_placeholder_adminname")}
                                   value={this.state.adminName} onChange={this.fieldUpdated}
                                   keyDownHandler={this.keyDownHandler} /><br />
            <ScanHowInputTextField fieldName="email"
                                   placeholderText={DkScanHowLocalisation.t("web_createorganisation_placeholder_emaillogin")}
                                   value={this.state.email} onChange={this.fieldUpdated}
                                   keyDownHandler={this.keyDownHandler} /><br />
            <br />
            {this.props.authUser ? null
              : <div>
                <ScanHowInputTextField fieldName="pass1"
                                       placeholderText={DkScanHowLocalisation.t("web_createorganisation_placeholder_passwordmin6chars")}
                                       value={this.state.pass1} onChange={this.fieldUpdated} type="password"
                                       keyDownHandler={this.keyDownHandler} /><br />
                <br />
                <ScanHowInputTextField fieldName="pass2"
                                       placeholderText={DkScanHowLocalisation.t("web_createorganisation_placeholder_repeatpassword")}
                                       value={this.state.pass2} onChange={this.fieldUpdated} type="password"
                                       keyDownHandler={this.keyDownHandler} /><br />
              </div>
            }
          </div>
        </div>
        <br />
        <div>
          <table className="fullWidth">
            <tbody>
            <tr>
              <td>
                <div className="accountFormErrorMessage">{this.state.errorMsg}</div>
              </td>
              <td className="right"><ScanHowButton text={DkScanHowLocalisation.t("web_createorganisation_btn_continue")}
                                                   onClick={this.handleContinueClick} /></td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    )
  }
}

class OrganisationDataCard extends React.Component {
  constructor(props) {
    super(props)
    this.fieldUpdated = this.fieldUpdated.bind(this)
    this.keyDownHandler = this.keyDownHandler.bind(this)
    this.displayEula = this.displayEula.bind(this)
    this.eulaChecked = this.eulaChecked.bind(this)

    this.state = { checked: false }
    this.twoFactorAuthEnabled = this.twoFactorAuthEnabled.bind(this)
    this.twoFactorDiv = this.twoFactorDiv.bind(this)
  }

  fieldUpdated(e) {
    const name = e.target.name
    const value = e.target.value
    this.props.updateFormState(name, value)
  }

  keyDownHandler(e) {
    this.props.keyDownHandler(e)
  }

  eulaChecked(e) {
    this.props.updateFormState("eulaAccepted", e.target.checked)
  }

  displayEula() {
    this.props.showModalForm(true, <div
      dangerouslySetInnerHTML={{ __html: DkScanHowLocalisation.t("web_createorganisation_eula") }} />)
  }

  twoFactorDiv(e) {
    return e === true ? <div>
      {"+"}<ScanHowInputTextField fieldName="twoFactorNumber"
                                  placeholderText={DkScanHowLocalisation.t("two_factor_phone")}
                                  value={this.props.twoFactorNumber}
                                  onChange={this.fieldUpdated}
                                  type={"number"}
                                  keyDownHandler={this.keyDownHandler} />
      <p style={{ color: "lightgray" }}>Husk landekode (f.eks: 45123456789)</p>
    </div> : null
  }

  twoFactorAuthEnabled(e) {
    this.setState({
      checked: !this.state.checked,
    })
    if (this.state.checked === false) {
      this.props.updateFormState("twoFactorNumber", "")
    }
    this.props.updateFormState("twoFactorAccepted", e.target.checked)
  }

  showTwoFactorAuth() {
    if (this.props.showTwoFactor) {
      return <div>
        <div>
          <ScanhowCheckbox checkBoxName="2faCheckbox"
                           checkBoxText={DkScanHowLocalisation.t("two_factor_optional")}
                           checked={this.props.twoFactorAccepted}
                           onCheck={this.twoFactorAuthEnabled}>&nbsp;</ScanhowCheckbox>
        </div>
        {this.twoFactorDiv(this.props.twoFactorAccepted)}
      </div>
    }
  }


  render() {
    return (
      <div className="mdl-card mdl-shadow--2dp fullWidth">
        <div className="mdl-card__supporting-text">
          {DkScanHowLocalisation.t("web_createorganisation_allfieldsarerequiredifnotspecifiedotherwise")}
          <br />
          <ScanHowInputTextField fieldName="accountName"
                                 placeholderText={DkScanHowLocalisation.t("web_createorganisation_placeholder_institution")}
                                 value={this.props.accountName} onChange={this.fieldUpdated}
                                 keyDownHandler={this.keyDownHandler} /><br />
          <ScanHowInputTextField fieldName="address"
                                 placeholderText={DkScanHowLocalisation.t("web_createorganisation_placeholder_address")}
                                 value={this.props.address} onChange={this.fieldUpdated}
                                 keyDownHandler={this.keyDownHandler} /><br />
          <ScanHowInputTextField fieldName="address2"
                                 placeholderText={DkScanHowLocalisation.t("web_createorganisation_placeholder_address2")}
                                 value={this.props.address2} onChange={this.fieldUpdated}
                                 keyDownHandler={this.keyDownHandler} /><br />
          <ScanHowInputTextField fieldName="zip"
                                 placeholderText={DkScanHowLocalisation.t("web_createorganisation_placeholder_zip")}
                                 value={this.props.zip} onChange={this.fieldUpdated}
                                 keyDownHandler={this.keyDownHandler} /><br />
          <ScanHowInputTextField fieldName="city"
                                 placeholderText={DkScanHowLocalisation.t("web_createorganisation_placeholder_city")}
                                 value={this.props.city} onChange={this.fieldUpdated}
                                 keyDownHandler={this.keyDownHandler} /><br />
          <ScanHowInputTextField fieldName="country"
                                 placeholderText={DkScanHowLocalisation.t("web_createorganisation_placeholder_country")}
                                 value={this.props.country} onChange={this.fieldUpdated}
                                 keyDownHandler={this.keyDownHandler} /><br />
          <ScanHowInputTextField fieldName="phone"
                                 placeholderText={DkScanHowLocalisation.t("web_createorganisation_placeholder_phone")}
                                 value={this.props.phone} onChange={this.fieldUpdated}
                                 keyDownHandler={this.keyDownHandler} />
          <br />
          {this.showTwoFactorAuth()}
          <br />
          <ScanHowInputTextField fieldName="contactPerson"
                                 placeholderText={DkScanHowLocalisation.t("web_createorganisation_placeholder_contact")}
                                 value={this.props.contactPerson} onChange={this.fieldUpdated}
                                 keyDownHandler={this.keyDownHandler} /><br />
          <ScanHowInputTextField fieldName="contactEmail"
                                 placeholderText={DkScanHowLocalisation.t("web_createorganisation_placeholder_email")}
                                 value={this.props.contactEmail} onChange={this.fieldUpdated}
                                 keyDownHandler={this.keyDownHandler} /><br />
          <ScanHowInputTextField fieldName="cvrEan"
                                 placeholderText={DkScanHowLocalisation.t("web_createorganisation_placeholder_cvrean")}
                                 value={this.props.cvrEan} onChange={this.fieldUpdated}
                                 keyDownHandler={this.keyDownHandler} maxLength={25} /><br />
          <b>{DkScanHowLocalisation.t("web_createorganisation_label_onlyforcphmunicipality")} :</b>
          <ScanHowInputTextField fieldName="employeeId"
                                 placeholderText={DkScanHowLocalisation.t("web_createorganisation_placeholder_employeeid")}
                                 value={this.props.employeeId} onChange={this.fieldUpdated}
                                 keyDownHandler={this.keyDownHandler} />
          <div className="tooltip">
            <div className="tooltipButton" />
            <span className="tooltiptext">{DkScanHowLocalisation.t("web_createorganisation_tooltip_employeeid")}</span>
          </div>
          {
            (this.props.eulaAccepted != undefined && this.props.eulaAccepted != null) ?
              <div className="eulaChkBoxContainer"><ScanhowCheckbox checkBoxName="eulaChkBox"
                                                                    checkBoxText={
                                                                      <div>{DkScanHowLocalisation.t("web_createorganisation_chbbox_ivereadunderstoodandagreeswith")}
                                                                        <a href="#"
                                                                           onClick={this.displayEula}>{DkScanHowLocalisation.t("web_createorganisation_eulalink_eulaandprivacypolicy")}</a>
                                                                      </div>}
                                                                    checked={this.props.eulaAccepted}
                                                                    parentId="OrganisationDataCard"
                                                                    onCheck={this.eulaChecked}>&nbsp;</ScanhowCheckbox>
              </div>
              : null
          }
          <br />
        </div>
      </div>
    )
  }
}

OrganisationDataCard.defaultProps = {}
OrganisationDataCard.propTypes = {
  accountName: React.PropTypes.string.isRequired,
  address: React.PropTypes.string.isRequired,
  address2: React.PropTypes.string.isRequired,
  zip: React.PropTypes.string.isRequired,
  city: React.PropTypes.string.isRequired,
  country: React.PropTypes.string.isRequired,
  phone: React.PropTypes.string.isRequired,
  twoFactorAccepted: React.PropTypes.bool.isRequired,
  twoFactorNumber: React.PropTypes.string.isRequired,
  twoFactorVerified: React.PropTypes.bool.isRequired,
  contactPerson: React.PropTypes.string.isRequired,
  contactEmail: React.PropTypes.string.isRequired,
  cvrEan: React.PropTypes.string.isRequired,
  employeeId: React.PropTypes.string,
  updateFormState: React.PropTypes.func.isRequired,
  showModalForm: React.PropTypes.func,
  showTwoFactor: React.PropTypes.bool,
}

class ChoosePlanCard extends React.Component {
  constructor(props) {
    super(props)
    // This binding is necessary to make `this` work in the callback
    this.handleClick = this.handleClick.bind(this)
    this.props = props
    this.showText = this.showText.bind(this)
    /*this.accountTypeTitle = this.accountTypeTitle.bind(this);*/
  }

  showText(e) {
    var elem = document.getElementById("adminText" + this.props.accountType.id)
    if (elem) {
      elem.style.display = "block"
    }
  }

  hideText(e) {
    var elem = document.getElementById("adminText" + this.props.accountType.id)
    if (elem) {
      elem.style.display = "none"
    }
  }

  handleClick(e) {
    this.props.onChoosePlan(this.props.accountType)
  }

  /*
      accountTypeTitle(accountType) {
          return DkScanHowLocalisation.t(`web_accounttypes_${accountType.id}_title`);
      }
  */


  render() {

    //var enterpriseTitle= DkScanHowLocalisation.t("web_accounttypes_enterprise_title");
    var enterpriseDescription = DkScanHowLocalisation.t("web_accounttypes_enterprise_description").replace("%1", "<a href='mailto:info@scanhow.dk'>info@scanhow.dk</a>").replace("%2", "<a href='http://scanhow.com/kontakt/'>http://scanhow.com/kontakt/</a>")

    return (
      <div className="mdl-card mdl-shadow--2dp choosePlanCard center">
        <div className="mdl-card__title">
          <div
            className="mdl-card__title-text center">{DkScanHowLocalisation.accountTypeTitle(this.props.accountType.id)}</div>
        </div>
        <hr className="scanhowHr" />
        {this.props.accountType.id == "enterprise" ?
          <div className="choosePlanBreadCrumb">
            <br />
            {/*<div dangerouslySetInnerHTML={ {__html: this.props.accountType.description}} />*/}
            <div dangerouslySetInnerHTML={{ __html: enterpriseDescription }} />
          </div>
          :
          <div>
            <div className="choosePlanBreadCrumb">
              <br />
              <div onMouseOver={this.showText.bind(this)} onMouseOut={this.hideText.bind(this)}>
                {DkScanHowLocalisation.t("web_createorganissation_label_price")} {this.props.accountType.pricePerMonth}
                <ChoosePlanBullets accountType={this.props.accountType} />
              </div>
              <br />
            </div>
            <div className="mdl-card__actions">
              <ScanHowButton onClick={this.handleClick} text="Vælg" />
            </div>

          </div>
        }
      </div>
    )
  }
}

class ChoosePlan extends React.Component {
  constructor(props) {
    super(props)
    // This binding is necessary to make `this` work in the callback
    this.handleChoosePlan = this.handleChoosePlan.bind(this)
    this.props = props
    this.state = { chosenPlan: "" }
  }

  handleChoosePlan(plan) {
    this.setState({ chosenPlan: plan.id })
    this.props.onChangePlan(plan)
  }

  render() {
    const isLoggingIn = this.state.isLoggingIn
    const planCards = this.props.accountTypes ? this.props.accountTypes.map((accountType) =>
      <div className="mdl-cell mdl-cell--3-col" key={accountType.id}>
        <ChoosePlanCard accountType={accountType} onChoosePlan={this.handleChoosePlan} />
      </div>,
    ) : null

    return (
      <div id="ChoosePlan">
        <div className="center">
          <div className="breadCrumb">
            <b>{DkScanHowLocalisation.t("web_createorganisation_title_choosemostfittingplan")}</b></div>
        </div>
        <br />
        <div className="choosePlanCards mdl-grid mdl-grid--no-spacing ">
          <div className="mdl-layout-spacer"></div>
          {planCards}
          <div className="mdl-layout-spacer"></div>
        </div>
        <br />
        <div className="center">

          <b className="smaller">{DkScanHowLocalisation.t("web_createorganisation_label_areyouindoubtwrite")}: <a
            href="mailto:info@scanhow.dk">info@scanhow.dk</a></b><br /><br />

          <b>{DkScanHowLocalisation.t("web_general_terms_admin")}:</b> {DkScanHowLocalisation.t("web_createorganisation_label_admindescription")}<br /><br />

          <b>{DkScanHowLocalisation.t("web_general_terms_creator")}:</b> {DkScanHowLocalisation.t("web_createorganisation_label_creatordescription")}<br /><br />

          <b>{DkScanHowLocalisation.t("web_general_terms_viewer")}:</b> {DkScanHowLocalisation.t("web_createorganisation_label_viewerdescription")}<br /><br />

          <b>{DkScanHowLocalisation.t("web_general_terms_workflow")}:</b> {DkScanHowLocalisation.t("web_createorganisation_label_workflowdescription")}<br /><br />

          <b>{DkScanHowLocalisation.t("web_general_terms_storagespace")}:</b> {DkScanHowLocalisation.t("web_createorganisation_label_storagespacedescription")}
        </div>
      </div>
    )
  }
}


class AccountForm extends React.Component {
  constructor(props) {
    super(props)
    this.props = props

    console.log("AccountForm")
    this.state = {
      shouldValidate: false,
      email: "",
      pass1: "",
      pass2: "",
      accountName: "",
      address: "",
      address2: "",
      zip: "",
      city: "",
      country: "",
      phone: "",
      twoFactorAccepted: false,
      twoFactorNumber: "",
      twoFactorVerified: false,
      contactPerson: "",
      contactEmail: "",
      cvrEan: "",
      employeeId: "",
      errorMsg: "",
      eulaAccepted: false,
    }
    this.handleContinueClick = this.handleContinueClick.bind(this)
    this.areCardsValidated = this.areCardsValidated.bind(this)
    this.updateFormState = this.updateFormState.bind(this)
    this.accountUpdated = this.accountUpdated.bind(this)
    this.accountUpdateFailed = this.accountUpdateFailed.bind(this)
    this.keyDownHandler = this.keyDownHandler.bind(this)
  }

  validateForm() {
    if (this.state.email != "" && this.state.pass1 != "" && this.state.pass2 != "" && this.state.pass1 == this.state.pass2 && this.state.accountName != "") {

    }
  }

  updateFormState(key, value) {
    this.setState({ [key]: value }, () => this.validateForm())
  }

  handleContinueClick() {
    if (this.areCardsValidated()) {
      DkScanHowHelpers.displayModal(true)
      this.updateAccount(this.props.authUser)
    } else {
      this.setState({ errorMsg: DkScanHowLocalisation.t("web_createorganisation_errormsg_requiredfieldmissing") })
    }
  }

  keyDownHandler(e) {
    //Pressed enter
    if (e.keyCode === 13) {
      this.handleContinueClick()
    }
  }

  updateAccount(user) {
    //Push doesn't create anything in DB untill ref().update() is called further down
    var newAccountRef = firebase.database().ref("vælg").push()
    var newAccountKey = newAccountRef.key

    var userAndAccount = {}
    userAndAccount["users/" + user.uid + "/accountKey"] = newAccountKey

    userAndAccount["accountUsers/" + newAccountKey + "/" + user.uid] = "admin"

    userAndAccount[DkScanHowDbRefs.Accounts + "/" + newAccountKey + "/" + DkScanHowDbRefs.Public] = {
      accountName: this.state.accountName,
      accountType: this.props.chosenPlan,
      address: this.state.address,
      address2: this.state.address2,
      zip: this.state.zip,
      city: this.state.city,
      country: this.state.country,
      phone: this.state.phone,
      twoFactorNumber: this.state.twoFactorNumber,
      twoFactorAccepted: this.state.twoFactorAccepted,
      twoFactorVerified: this.state.twoFactorVerified,
      contactPerson: this.state.contactPerson,
      contactEmail: this.state.contactEmail,
      cvrEan: this.state.cvrEan,
      employeeId: this.state.employeeId,
      chosenPlan: this.props.chosenPlan,
      accountKey: newAccountKey,
      eulaAccepted: this.state.eulaAccepted,
      createdTime: firebase.database.ServerValue.TIMESTAMP,
    }

    firebase.database().ref().update(userAndAccount).then(
      this.accountUpdated,
      this.accountUpdateFailed,
    )
  }

  accountUpdated() {
    DkScanHowHelpers.displayModal(false)
    this.props.onCreation()
  }

  accountUpdateFailed(error) {
    this.setState({ errorMsg: DkScanHowLocalisation.t("web_general_errormsg_anerroroccurred") })
    DkScanHowHelpers.logError(error)
    //console.log("accountUpdateFailed: "+error);
    DkScanHowHelpers.displayModal(false)
  }

  componentDidUpdate(prevProps, prevState) {
    // This upgrades all upgradable components (i.e. with 'mdl-js-*' class)*/
    componentHandler.upgradeDom()
  }

  componentDidMount() {
    if (this.props.authUser && this.props.account) {
      firebase.database().ref(DkScanHowDbRefs.Accounts + "/" + this.props.account.accountKey + "/" + DkScanHowDbRefs.Public).once("value").then((snapshot) => {
        if (snapshot && snapshot.val()) {
          this.setState({ email: this.props.authUser.email, accountName: snapshot.val().accountName })
        }
      }, function(error) {
        // An error happened.
        DkScanHowHelpers.logError(error)
      })
    }
    componentHandler.upgradeDom()
  }

  areCardsValidated() {
    return this.state.accountName != "" &&
      this.state.address != "" &&
      this.state.zip != "" &&
      this.state.city != "" &&
      this.state.country != "" &&
      this.state.phone != "" &&
      this.state.contactPerson != "" &&
      this.state.contactEmail != "" &&
      this.state.cvrEan &&
      this.state.eulaAccepted &&
      (this.state.twoFactorAccepted && this.state.twoFactorNumber.length > 1 || !this.state.twoFactorAccepted && this.state.twoFactorNumber.length < 1)
  }

  render() {
    /*console.log("AccountForm render : " + JSON.stringify( this.state ));*/
    return (
      <div>
        <div className="mdl-grid mdl-grid--no-spacing  ">
          <div className="mdl-layout-spacer"></div>
          <div className="mdl-cell--3-col">

            <table>
              <tbody>
              <tr>
                <td>
                  <div></div>
                </td>
                <td>
                  <h3>{DkScanHowLocalisation.t("web_createorganisation_title_customerdata")}</h3>
                  <OrganisationDataCard updateFormState={this.updateFormState} accountName={this.state.accountName}
                                        address={this.state.address} address2={this.state.address2} zip={this.state.zip}
                                        city={this.state.city} country={this.state.country} phone={this.state.phone}
                                        twoFactorNumber={this.state.twoFactorNumber}
                                        twoFactorAccepted={this.state.twoFactorAccepted}
                                        contactPerson={this.state.contactPerson} contactEmail={this.state.contactEmail}
                                        cvrEan={this.state.cvrEan} employeeId={this.state.employeeId}
                                        eulaAccepted={this.state.eulaAccepted} keyDownHandler={this.keyDownHandler}
                                        showModalForm={this.props.showModalForm} showTwoFactor={false} />
                  <br />
                  <div>
                    <table className="fullWidth">
                      <tbody>
                      <tr>
                        <td>
                          <div className="accountFormErrorMessage">{this.state.errorMsg}</div>
                        </td>
                        <td className="right"><ScanHowButton
                          text={DkScanHowLocalisation.t("web_createorganisation_btn_continue")}
                          onClick={this.handleContinueClick} /></td>
                      </tr>
                      </tbody>
                    </table>
                  </div>
                </td>
              </tr>
              </tbody>
            </table>

          </div>
          <div className="mdl-layout-spacer"></div>
        </div>
      </div>
    )
  }
}

// Specifies the default values for props:
AccountForm.propTypes = {
  chosenPlan: React.PropTypes.string.isRequired,
  onCreation: React.PropTypes.func,
  authUser: React.PropTypes.object,
}


class ConfirmPlan extends React.Component {
  constructor(props) {
    super(props)
    this.props = props
    this.state = {
      errorMsg: "", extras: {}, waitingForAccountSetup: this.props.account.debitorNo == null,
      courses: null, support: null, storage: null, workflows: null,
    }
    this.handleConfirmPlanClick = this.handleConfirmPlanClick.bind(this)
    this.accountUpdated = this.accountUpdated.bind(this)
    this.accountUpdateFailed = this.accountUpdateFailed.bind(this)
    this.updateAccount = this.updateAccount.bind(this)
    this.extrasUpdated = this.extrasUpdated.bind(this)

    if (this.props.account.debitorNo == null) {
      var debitorNoRef = firebase.database().ref(DkScanHowDbRefs.Accounts + "/" + this.props.account.accountKey + "/" + DkScanHowDbRefs.Public + "/debitorNo")
      debitorNoRef.on("value", (snapshot) => {
        if (snapshot && snapshot.val()) {
          debitorNoRef.off()
          this.setState({ "waitingForAccountSetup": false })
        }
      })
    }
  }

  componentDidUpdate(prevProps, prevState) {
    componentHandler.upgradeDom()
  }

  componentDidMount() {

    componentHandler.upgradeDom()
  }

  handleConfirmPlanClick() {
    this.updateAccount()
    /*console.log("handleConfirmPlanClick");*/
  }

  updateAccount() {
    if (this.props.account.debitorNo != null) {

      var newPurchaseRef = firebase.database().ref("userQueues/purchases/" + this.props.authUser.uid + "/purchaseKey").push()
      var newPurchaseKey = newPurchaseRef.key
      var accountAndPurchase = {}

      var purchase = DkScanHowHelpers.purchaseJson(this.props.chosenPlan, this.props.authUser.uid, this.props.account.debitorNo, this.props.account.accountKey, this.state.courses, this.state.storage, this.state.support, this.state.workflows, null)

      accountAndPurchase["userQueues/purchases/" + this.props.authUser.uid + "/" + newPurchaseKey] = purchase
      accountAndPurchase[DkScanHowDbRefs.Accounts + "/" + this.props.account.accountKey + "/" + DkScanHowDbRefs.Public + "/confirmedPlan"] = this.props.chosenPlan

      console.log("accountAndPurchase : " + JSON.stringify(accountAndPurchase))

      firebase.database().ref().update(accountAndPurchase).then(
        this.accountUpdated,
        this.accountUpdateFailed,
      )
    }
  }

  accountUpdated() {
    this.props.onConfirmPlan(this.props.chosenPlan)
  }

  accountUpdateFailed(error) {
    this.setState({ errorMsg: DkScanHowLocalisation.t("web_general_errormsg_anerroroccurred") })
    DkScanHowHelpers.logError(error)
    console.log("accountUpdateFailed.: " + error)
  }

  componentDidMount() {
    componentHandler.upgradeDom()
  }

  extrasUpdated(item) {
    var extras = this.state.extras
    if (item && item.id && item.chosen) {
      extras[item.id] = item
      this.setState({ [item.id]: item.chosen, extras: extras })
    }
  }

  render() {
    console.log("confirmPlan " + this.props + " / " + JSON.stringify(this.state))
    return (
      <div>
        <div className="mdl-grid mdl-grid--no-spacing  ">
          <div className="mdl-cell--1-col" />
          <div className="mdl-cell--6-col">
            <ExtendedPlanCard chosenPlan={this.props.chosenPlan} extrasUpdated={this.extrasUpdated}
                              extras={this.state.extras} account={this.props.account} />
          </div>
          <div className="mdl-cell--1-col">&nbsp;</div>
          <div className="mdl-layout-spacer"></div>
          <div className="mdl-cell--3-col">
            <ChosenPlanCard chosenPlan={this.props.chosenPlan} extras={this.state.extras}
                            account={this.props.account} />
            <br />
            <div className="right">
              <div className="accountFormErrorMessage">{this.state.errorMsg}</div>
              {this.props.onCancel != null ?
                <ScanHowButton text={DkScanHowLocalisation.t("web_createorganisation_accountcard_btn_regret")}
                               onClick={this.props.onCancel} /> : null}
              <ScanHowButton text={DkScanHowLocalisation.t("web_createorganisation_accountcard_btn_confirm")}
                             onClick={this.handleConfirmPlanClick} isDisabled={this.state.waitingForAccountSetup} />
              {this.state.waitingForAccountSetup ?
                <div>
                  <br />
                  <div
                    className="centeredBoth">{DkScanHowLocalisation.t("web_createorganisation_accountcard_label_waitamomentwhileyouraccountissetup")}</div>
                </div> : null
              }
            </div>
          </div>
          <div className="mdl-cell--1-col" />

        </div>

      </div>
    )
  }
}

ConfirmPlan.defaultProps = {}
ConfirmPlan.propTypes = {
  authUser: React.PropTypes.object.isRequired,
  account: React.PropTypes.object,
  chosenPlan: React.PropTypes.string.isRequired,
  onConfirmPlan: React.PropTypes.func.isRequired,
  onCancel: React.PropTypes.func,
}

class OverviewPlanCard extends React.Component {
  constructor(props) {
    super(props)
    /*console.log(("ChosenPlanCard : " +JSON.stringify(props)));*/
    // This binding is necessary to make `this` work in the callback
    this.props = props
    this.state = { accountType: null }

    firebase.database().ref("accountTypes/").orderByChild("id").equalTo(this.props.chosenPlan).limitToFirst(1).once("value").then((snapshot) => {
      if (snapshot) {
        let accountType = DkScanHowHelpers.objectToArray(snapshot.val())[0]
        if (!this.state.accountType) {
          this.setState({
            accountType: accountType,
          })
        }
      }
    }, function(error) {
      // An error happened.
      DkScanHowHelpers.logError(error)
      console.log("Error while reading account types: " + error)
    })
  }

  render() {
    return (
      <div className="mdl-card mdl-shadow--2dp fullWidth">
        <div className="mdl-card__title">
          <h2
            className="mdl-card__title-text">{DkScanHowLocalisation.t("web_createorganisation_cardtitle_youraccount")}</h2>
        </div>
        <div className="">
          {this.state.accountType ? <ChoosePlanBullets accountType={this.state.accountType} /> : null}
          <hr />

        </div>
      </div>
    )
  }
}

OverviewPlanCard.defaultProps = {}
OverviewPlanCard.propTypes = {
  chosenPlan: React.PropTypes.string.isRequired,

}

class ChosenPlanCard extends React.Component {
  constructor(props) {
    super(props)
    /*console.log(("ChosenPlanCard : " +JSON.stringify(props)));*/
    // This binding is necessary to make `this` work in the callback
    this.props = props
    this.state = { accountType: null }
  }

  componentDidUpdate(prevProps, prevState) {
    componentHandler.upgradeDom()
  }

  componentDidMount() {
    firebase.database().ref("accountTypes/").orderByChild("id").equalTo(this.props.chosenPlan).limitToFirst(1).once("value").then((snapshot) => {
      if (snapshot) {
        let accountType = DkScanHowHelpers.objectToArray(snapshot.val())[0]
        if (!this.state.accountType) {
          this.setState({
            accountType: accountType,
          })
        }
      }
    }, function(error) {
      // An error happened.
      DkScanHowHelpers.logError(error)
    })
    componentHandler.upgradeDom()
  }


  render() {
    var basket = this.state.accountType ?
      <Basket accountType={this.state.accountType} extras={this.props.extras} /> : null
    return (
      <div className="mdl-card mdl-shadow--2dp fullWidth">
        <div className="mdl-card__title">
          <h2
            className="mdl-card__title-text">{DkScanHowLocalisation.t("web_createorganisation_cardtitle_youraccount")}</h2>
        </div>
        <div className="mdl-card__supporting-text ">
          <table>
            <tbody>
            <tr>
              <td>{DkScanHowLocalisation.t("web_createorganisation_label_package")}:</td>
              <td>{this.state.accountType ? DkScanHowLocalisation.accountTypeTitle(this.state.accountType.id) : ""}</td>
            </tr>
            <tr>
              <td colSpan="2">&nbsp;</td>
            </tr>
            <tr>
              <td>{DkScanHowLocalisation.t("web_createorganisation_label_organisation")}:</td>
              <td>{this.props.account.accountName}</td>
            </tr>
            <tr>
              <td>{DkScanHowLocalisation.t("web_createorganisation_accountcard_label_address")}:</td>
              <td>{this.props.account.address}</td>
            </tr>
            <tr>
              <td>{DkScanHowLocalisation.t("web_createorganisation_accountcard_label_address")}:</td>
              <td>{this.props.account.address2}</td>
            </tr>
            <tr>
              <td>{DkScanHowLocalisation.t("web_createorganisation_accountcard_label_zip")}:</td>
              <td>{this.props.account.zip}</td>
            </tr>
            <tr>
              <td>{DkScanHowLocalisation.t("web_createorganisation_accountcard_label_city")}:</td>
              <td>{this.props.account.city}</td>
            </tr>
            <tr>
              <td>{DkScanHowLocalisation.t("web_createorganisation_accountcard_label_country")}:</td>
              <td>{this.props.account.country}</td>
            </tr>
            <tr>
              <td>{DkScanHowLocalisation.t("web_createorganisation_accountcard_label_phone")}:</td>
              <td>{this.props.account.phone}</td>
            </tr>
            <tr>
              <td>{DkScanHowLocalisation.t("web_createorganisation_accountcard_label_contact")}:</td>
              <td>{this.props.account.contactPerson}</td>
            </tr>
            <tr>
              <td>{DkScanHowLocalisation.t("web_createorganisation_accountcard_label_email")}:</td>
              <td>{this.props.account.contactEmail}</td>
            </tr>
            <tr>
              <td>{DkScanHowLocalisation.t("web_createorganisation_accountcard_label_cvrean")}:</td>
              <td>{this.props.account.cvrEan}</td>
            </tr>
            </tbody>
          </table>
          <hr />
          {basket}
        </div>
      </div>
    )
  }
}

ChosenPlanCard.defaultProps = {}
ChosenPlanCard.propTypes = {
  chosenPlan: React.PropTypes.string.isRequired,
  extras: React.PropTypes.object.isRequired,
  account: React.PropTypes.object.isRequired,
}

class Basket extends React.Component {
  constructor(props) {
    super(props)
    this.props = props
  }

  componentDidUpdate() {
    componentHandler.upgradeDom()
  }

  componentDidMount() {
    componentHandler.upgradeDom()
  }

  render() {
    var packageLine = null
    var storageLine = null
    var workflowsLine = null
    var totalMonthlyLine = null
    var totalMonthly = 0

    if (this.props.accountType.pricePerMonth) {
      packageLine = <tr>
        <td>{DkScanHowLocalisation.t("web_createorganisation_accountcard_label_subscription")}:</td>
        <td>{this.props.accountType.pricePerMonth} {DkScanHowLocalisation.t("web_createorganisation_accountcard_label_krpermonth")}</td>
      </tr>
      totalMonthly += this.props.accountType.pricePerMonth
    }

    if (this.props.extras.storage && this.props.extras.storage.price) {
      storageLine = <tr>
        <td>{DkScanHowLocalisation.productTitle(this.props.extras.storage.chosen)}:</td>
        <td>{this.props.extras.storage.price} {DkScanHowLocalisation.t("web_createorganisation_accountcard_label_krpermonth")}</td>
      </tr>
      totalMonthly += this.props.extras.storage.price
    }

    if (this.props.extras.workflows && this.props.extras.workflows.price) {
      workflowsLine = <tr>
        <td>{DkScanHowLocalisation.productTitle(this.props.extras.workflows.chosen)}
          :
        </td>
        <td>{this.props.extras.workflows.price} {DkScanHowLocalisation.t("web_createorganisation_accountcard_label_krpermonth")}</td>
      </tr>
      totalMonthly += this.props.extras.workflows.price
    }

    totalMonthlyLine = <tr>
      <td>{DkScanHowLocalisation.t("web_createorganisation_accountcard_label_total")}:</td>
      <td>{totalMonthly} {DkScanHowLocalisation.t("web_createorganisation_accountcard_label_krpermonth")}</td>
    </tr>

    var courseLine = null
    var supportLine = null
    var totalNowLine = null
    var totalNow = 0

    if (this.props.extras.courses && this.props.extras.courses.price) {
      courseLine = <tr>
        <td>{DkScanHowLocalisation.productTitle(this.props.extras.courses.chosen)}:</td>
        <td>{this.props.extras.courses.price} kr.</td>
      </tr>
      totalNow += this.props.extras.courses.price
    }

    if (this.props.extras.support && this.props.extras.support.price) {
      supportLine = <tr>
        <td>{DkScanHowLocalisation.productTitle(this.props.extras.support.chosen)}:</td>
        <td>{this.props.extras.support.price} kr.</td>
      </tr>
      totalNow += this.props.extras.support.price
    }

    if (totalNow != 0) {
      totalNowLine = <tr>
        <td>{DkScanHowLocalisation.t("web_createorganisation_accountcard_label_onetimeamount")}:</td>
        <td>{totalNow} kr.</td>
      </tr>
    }

    return (
      <div>
        <table className="basket left">
          <tbody>
          {packageLine}
          {storageLine}
          {workflowsLine}
          {totalMonthlyLine ? <tr>
            <td colSpan="2">
              <hr />
            </td>
          </tr> : null}
          {totalMonthlyLine}

          {totalNowLine ? <tr>
            <td colSpan="2"><br /><br /></td>
          </tr> : null}
          {totalNowLine ? <tr>
            <td colSpan="2"><br /><br /></td>
          </tr> : null}
          {courseLine}
          {supportLine}
          {totalNowLine ? <tr>
            <td colSpan="2">
              <hr />
            </td>
          </tr> : null}
          {totalNowLine}
          </tbody>
        </table>
      </div>
    )
  }
}

Basket.defaultProps = {}
Basket.propTypes = {
  extras: React.PropTypes.object,
  accountType: React.PropTypes.object,
}

class ProductLine extends React.Component {
  constructor(props) {
    super(props)
    this.props = props
    this.onRadioCheck = this.onRadioCheck.bind(this)
  }

  componentDidUpdate() {
    componentHandler.upgradeDom()
  }

  componentDidMount() {
    if (this.props.selected) {
      this.onRadioCheck()
    }
    componentHandler.upgradeDom()

  }

  onRadioCheck() {
    this.props.onRadioCheck({
        "id": this.props.radioGroup,
        "chosen": this.props.data.productId,
        "price": this.props.data.price,
      },
    )
  }

  render() {
    return (
      <tr>
        <td>
          <ScanhowRadio
            checked={this.props.extras != null && this.props.extras[this.props.radioGroup] != null && this.props.extras[this.props.radioGroup].chosen == this.props.data.productId}
            onCheck={this.onRadioCheck}
            radioGroup={this.props.radioGroup}
            radioText={<div
              dangerouslySetInnerHTML={{ __html: DkScanHowLocalisation.productDescription(this.props.radioGroup, this.props.data) }} />}
            radioName={this.props.data.productId}
          />
        </td>
      </tr>
    )
  }
}

ProductLine.defaultProps = {}
ProductLine.propTypes = {
  extras: React.PropTypes.object,
  onRadioCheck: React.PropTypes.func.isRequired,
  radioGroup: React.PropTypes.string.isRequired,
  selected: React.PropTypes.bool,
}

class ExtendedPlanCard extends React.Component {
  constructor(props) {
    super(props)
    this.props = props
    this.state = { courses: null, support: null, workflows: null, storage: null }
    /*console.log("ExtendedPlanCard constructor" + this.props);*/
    this.onCheck = this.onCheck.bind(this)
    this.onRadioCheck = this.onRadioCheck.bind(this)
    this.getProductLines = this.getProductLines.bind(this)
  }

  componentDidUpdate() {
    componentHandler.upgradeDom()
    /*this.textInput.MaterialTextfield.checkDirty();*/
  }

  componentDidMount() {
    componentHandler.upgradeDom()

    firebase.database().ref("extras").once("value").then((snapshot) => {
      if (snapshot && snapshot.val()) {
        var courses = null
        var support = null
        var workflows = null
        var storage = null
        snapshot.forEach((child) => {
          if (child.key == "courses") {
            courses = child.val()
          } else if (child.key == "support") {
            support = child.val()
          } else if (child.key == "workflows") {
            workflows = child.val()
          } else if (child.key == "storage") {
            storage = child.val()
          }
        })
        this.setState({ courses: courses, support: support, workflows: workflows, storage: storage })
      }
    }, function(error) {
      // An error happened.
      DkScanHowHelpers.logError(error)
      console.log("Error while getting extras: " + error)
    })
  }

  onCheck(e) {
    console.log("onCheck")
    this.props.extrasUpdated({
      "id": e.target.id,
      "chosen": e.target.checked,
    })
  }

  onRadioCheck(item) {
    this.props.extrasUpdated(item)
  }

  getProductLines(productType) {
    var productLines = []
    var tempArray = DkScanHowHelpers.objectToArray(this.state[productType])
    if (tempArray.length > 0) {
      var sorted = DkScanHowHelpers.objectToArraySorted(this.state[productType], "quantity")
      var containsSelected = false
      for (var index = 0; index < sorted.length; index++) {
        let key = sorted[index]["key"]
        var productData = this.state[productType][key]
        var selected = key == DkScanHowHelpers.getJsonDescendant(this.props.account, "latestPurchase." + productType + ".productId", null, null)
        if (index == (sorted.length - 1) && !containsSelected) {
          selected = true
        }
        containsSelected = containsSelected || selected
        productLines.push(<ProductLine key={key} extras={this.props.extras} onRadioCheck={this.onRadioCheck}
                                       radioGroup={productType} data={productData} selected={selected} />)
      }
    }
    return productLines
  }

  render() {
    var courseProductLines = this.state.courses ? this.getProductLines("courses") : null
    var supportProductLines = this.state.support ? this.getProductLines("support") : null
    var workflowProductLines = this.state.workflows ? this.getProductLines("workflows") : null
    var storageProductLines = this.state.storage ? this.getProductLines("storage") : null

    console.log("ExtendedPlanCard render" + this.props)
    return (
      <div className="mdl-card mdl-shadow--2dp fullWidth extendedPlanCard">
        <div className="mdl-card__title">
          <h2
            className="mdl-card__title-text">{DkScanHowLocalisation.t("web_createorganisation_extrascard_title_extras")}</h2>
        </div>
        <div className="mdl-card__supporting-text">
          <div className="hiddenText 275">
            <div><h3>{DkScanHowLocalisation.t("web_createorganisation_extrascard_subtitle_course")}</h3></div>
            <table>
              <tbody>
              {courseProductLines}
              </tbody>
            </table>
            <div><h3>{DkScanHowLocalisation.t("web_createorganisation_extrascard_subtitle_support")}</h3></div>
            {DkScanHowLocalisation.t("web_createorganisation_extrascard_label_supportdescription")}
            <br /><br />
            <table>
              <tbody>
              {supportProductLines}
              </tbody>
            </table>
          </div>
          <div><h3>{DkScanHowLocalisation.t("web_createorganisation_extrascard_title_workflows")}</h3></div>
          {DkScanHowLocalisation.t("web_createorganisation_extrascard_label_workflowsdescription")}
          <br /><br />
          <table>
            <tbody>
            {workflowProductLines}
            </tbody>
          </table>
          <div><h3>{DkScanHowLocalisation.t("web_createorganisation_extrascard_title_storage")}</h3></div>
          {DkScanHowLocalisation.t("web_createorganisation_extrascard_label_storagedescription")}
          <br /><br />
          <table>
            <tbody>
            {storageProductLines}
            </tbody>
          </table>
        </div>
      </div>
    )
  }
}

ExtendedPlanCard.defaultProps = {}
ExtendedPlanCard.propTypes = {
  chosenPlan: React.PropTypes.string.isRequired,
  extras: React.PropTypes.object,
  account: React.PropTypes.object,
}

function ChoosePlanBullet(props) {
  return (
    <li className=""><span className="">{props.text}</span></li>
  )
}

function ChoosePlanBullets(props) {
  /*console.log("ChoosePlanBullets :"+JSON.stringify( props) + " / " + props.accountType);*/
  return (

    <ul className="mdl-list center">
      <ChoosePlanBullet
        text={`${props.accountType.noOfCreators} ${DkScanHowLocalisation.t("web_general_terms_creators")}`} />
      <ChoosePlanBullet
        text={`${props.accountType.noOfViewers} ${DkScanHowLocalisation.t("web_general_terms_viewers")}`} />
      <ChoosePlanBullet
        text={`${props.accountType.storageInGB} GB ${DkScanHowLocalisation.t("web_general_terms_storage")}`} />
      <ChoosePlanBullet
        text={`${props.accountType.noOfWorkflows} ${DkScanHowLocalisation.t("web_general_terms_workflows")}`} />
      <ChoosePlanBullet
        text={`${props.accountType.noOfAdmins} ${DkScanHowLocalisation.t("web_general_terms_admin")}`} />

    </ul>
  )
}